package de.n4.careflex.login

import de.n4.careflex.common.Message
import de.n4.careflex.common.MessageLevel
import de.n4.careflex.common.component.messageComponent
import de.n4.careflex.infrastructure.ApplicationContext
import de.n4.careflex.login.exceptions.AuthenticationFailedException
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import react.*
import react.dom.*

class LoginForm(props: LoginFormProps): RComponent<LoginFormProps, LoginFormState>(props) {

    private val authService = props.applicationContext.authService
    private val mainScope = props.applicationContext.mainScope

    private val submitButton = createRef<HTMLButtonElement>()

    override fun LoginFormState.init(props: LoginFormProps) {
        username = ""
        password = ""
        message = null
    }

    private val onUsernameChange: (Event) -> Unit = { event ->
        setState {
            username = (event.target as HTMLInputElement).value
        }
    }

    private val onPasswordChange: (Event) -> Unit = { event ->
        setState {
            password = (event.target as HTMLInputElement).value
        }
    }

    private val onFormSubmit: (Event) -> Unit = { event ->
        event.preventDefault()
        mainScope.launch {
            submitButton.current.disabled = true
            try {
                authService.authenticate(state.username, state.password)
            } catch (e: AuthenticationFailedException) {
                submitButton.current.disabled = false
                setState {
                    message = Message(MessageLevel.WARNING, e.toString())
                }
            } catch (e: AuthenticationFailedException) {
                submitButton.current.disabled = false
                setState {
                    message = Message(MessageLevel.ERROR, e.toString())
                }
            }
        }
    }

    override fun RBuilder.render() {
        div(classes = "container") {
            h1 {
                + "Careflex Login"
            }
            state.message?.also { msg ->
                messageComponent { message = msg }
            }
            form {
                div(classes = "form-group") {
                    label {
                        attrs {
                            htmlFor = "usernameInput"
                        }
                        + "Service User ID"
                    }
                    input(
                        classes = "form-control",
                        name = "username",
                        type = InputType.text
                    ) {
                        attrs {
                            id = "usernameInput"
                            value = state.username
                            onChangeFunction = onUsernameChange
                        }
                    }
                }
                div(classes = "form-group") {
                    label {
                        attrs {
                            htmlFor = "passwordInput"
                        }
                        + "Service User Secret"
                    }
                    input(
                        classes = "form-control",
                        name = "password",
                        type = InputType.password
                    ) {
                        attrs {
                            id = "passwordInput"
                            value = state.password
                            onChangeFunction = onPasswordChange
                        }
                    }
                }
                button(
                    classes = "btn btn-primary",
                    type = ButtonType.submit
                ) {
                    attrs {
                        ref = submitButton
                        onClickFunction = onFormSubmit
                    }
                    + "Login"
                }
            }
        }
    }
}
external interface LoginFormProps: RProps {
    var applicationContext: ApplicationContext
}

external interface LoginFormState: RState {
    var username: String
    var password: String
    var message: Message?
}

fun RBuilder.loginForm(handler: LoginFormProps.() -> Unit): ReactElement =
    child(LoginForm::class) {
        this.attrs(handler)
    }