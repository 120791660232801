package de.n4.careflex.bootstrap

enum class Color(val identifier: String) {
    PRIMARY("primary"),
    SECONDARY("secondary"),
    SUCCESS("success"),
    DANGER("danger"),
    WARNING("warning"),
    INFO("info"),
    DARK("dark")
}