package de.n4.careflex.common

import org.w3c.dom.events.Event

/**
 * Simple implementation of the [org.w3c.dom.events.EventTarget].
 */
open class EventTargetImpl {

    private val listeners: MutableMap<String, MutableList<Callback>> = mutableMapOf()

    fun addEventListener(type: String, callback: Callback): Callback =
        listeners.getOrPut(type) {
            mutableListOf()
        }.let {
            it.add(callback)
            callback
        }

    fun removeEventListener(type: String, callback: Callback): Boolean =
        listeners[type]?.remove(callback)?:false

    fun dispatchEvent(event: Event): Boolean =
        listeners[event.type]?.let { callList ->
            callList.forEach { callback ->
                callback(event)
            }
            !event.defaultPrevented
        }?: true
}

typealias Callback = (Event) -> Unit