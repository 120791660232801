package de.n4.careflex.import

import de.n4.careflex.common.*
import de.n4.careflex.common.component.importListComponent
import de.n4.careflex.common.component.messageComponent
import de.n4.careflex.common.component.spinnerComponent
import de.n4.careflex.infrastructure.ApplicationContext
import kotlinx.coroutines.launch
import react.*
import react.dom.*

class ImportComponent(props: ImportComponentProps):RComponent<ImportComponentProps, ImportComponentState>(props) {

    override fun ImportComponentState.init(props: ImportComponentProps) {
        val importApi = props.applicationContext.importApi
        val mainScope = props.applicationContext.mainScope

        importResponse = null

        mainScope.launch {
            importApi.getCsvImportList(5).also { response ->
                setState {
                    importResponse = response
                }
            }
        }
    }

    private val importApi = props.applicationContext.importApi
    private val mainScope = props.applicationContext.mainScope

    private val onImportFormSuccess: (import: ImportDetailResponse) -> Unit = {
        mainScope.launch {
            importApi.getCsvImportList(5).also { response ->
                setState {
                    importResponse = response
                }
            }
        }
    }

    override fun RBuilder.render() {
        div(classes = "container-md") {
            h1 {
                +"Import CSV File"
            }
            importForm {
                applicationContext = props.applicationContext
                onImportSuccess = onImportFormSuccess
            }

            br { }

            h1 {
                + "Last 5 Imports"
            }
            state.importResponse?.also { response ->
                when(response) {
                    is ApiSuccess -> importListComponent {
                        imports = response.value.content.toList()
                        onImportClick = props.onImportSelect
                    }
                    is ApiError -> messageComponent {
                        message = Message(MessageLevel.ERROR, response.error.toString())
                    }
                }
            }?: div { spinnerComponent() }
        }
    }
}

external interface ImportComponentProps: RProps {
    var applicationContext: ApplicationContext
    var onImportSelect: (ImportResponse) -> Unit
}

external interface ImportComponentState: RState {
    var importResponse: ApiResponse<SummaryResponse<ImportResponse>>?
}

fun RBuilder.importComponent(handler: ImportComponentProps.() -> Unit): ReactElement =
    child(ImportComponent::class) {
        attrs(handler)
    }