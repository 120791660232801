package de.n4.careflex.import

import de.n4.careflex.common.*
import de.n4.careflex.common.component.messageComponent
import de.n4.careflex.infrastructure.ApplicationContext
import de.n4.careflex.infrastructure.TokenResponse
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onSubmitFunction
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.files.File
import react.*
import react.dom.*

class ImportForm(props: ImportFormProps): RComponent<ImportFormProps, ImportFormState>(props) {

    override fun ImportFormState.init(props: ImportFormProps) {
        message = null
        careflexId = ""
        apiToken = ""
        file = null
        isFormValidated = false
    }

    private val importApi = props.applicationContext.importApi
    private val mainScope = props.applicationContext.mainScope

    private val onCareflexIdChange: (Event) -> Unit = { event ->
        setState {
            careflexId = (event.target as HTMLInputElement).value
        }
    }

    private val onApiTokenChange: (Event) -> Unit = { event ->
        setState {
            apiToken = (event.target as HTMLInputElement).value
        }
    }

    private val onFileChange: (Event) -> Unit = { event ->
        val item = (event.target as HTMLInputElement).files!!.item(0)
        console.log("setting file: ", item)
        setState {
            file = item
        }
    }

    private val onFormSubmit: (Event) -> Unit = { event ->
        event.preventDefault()
        val form = event.target as HTMLFormElement
        // validate input
        val isValid = form.checkValidity()
        setState { isFormValidated = true }

        if (isValid) {
            // form is valid
            setState {
                message = null
            }
            mainScope.launch {
                console.log("importing...", state.careflexId, state.apiToken, state.file)
                submitButton.current.disabled = true
                try {
                    importApi.postImport(
                        careflexId = state.careflexId,
                        apiToken = state.apiToken,
                        file = state.file!!
                    ).let { apiResponse ->
                        when(apiResponse) {
                            is ApiSuccess -> setState {
                                message = Message(MessageLevel.INFO, "Import succeeded")
                                props.onImportSuccess(apiResponse.value)
                                isFormValidated = false
                            }
                            is ApiError -> setState {
                                message = Message(MessageLevel.ERROR, "Import failed: ${apiResponse.error.message}")
                            }
                        }
                    }
                } catch (e: Exception) {
                    setState {
                        message = Message(MessageLevel.ERROR,"Import error: $e")
                    }
                } finally {
                    submitButton.current.disabled = false
                }
            }
        } else {
            // form is invalid
            setState {
                message = Message(MessageLevel.ERROR, "Please fill in required fields.")
            }
        }
    }

    private val fileInput = createRef<HTMLInputElement>()
    private val submitButton = createRef<HTMLButtonElement>()

    override fun RBuilder.render() {
        state.message?.also { msg ->
            messageComponent {
                message = msg
            }
        }
        form {
            attrs {
                novalidate = true
                onSubmitFunction = onFormSubmit
                if (state.isFormValidated) {
                    classes = setOf("was-validated")
                }
            }
            div(classes = "form-group") {
                label {
                    attrs {
                        htmlFor = "careflexIdInput"
                    }
                    +"Careflex-Id"
                }
                input(
                    classes = "form-control",
                    name = "careflexId",
                    type = InputType.text
                ) {
                    attrs {
                        id = "careflexIdInput"
                        value = state.careflexId
                        required = true
                        onChangeFunction = onCareflexIdChange
                    }
                }
            }
            div(classes = "form-group") {
                label {
                    attrs {
                        htmlFor = "apiTokenIdInput"
                    }
                    +"API Token"
                }
                input(
                    classes = "form-control",
                    name = "apiToken",
                    type = InputType.text
                ) {
                    attrs {
                        id = "apiTokenIdInput"
                        value = state.apiToken
                        required = true
                        onChangeFunction = onApiTokenChange
                    }
                }
            }
            div(classes = "form-group") {
                label {
                    attrs {
                        htmlFor = "fileIdInput"
                    }
                    +"CSV File"
                }
                input(
                    classes = "form-control",
                    name = "file",
                    type = InputType.file
                ) {
                    attrs {
                        id = "fileIdInput"
                        ref = fileInput
                        required = true
                        onChangeFunction = onFileChange
                    }
                }
            }
            button(
                classes = "btn btn-primary",
                type = ButtonType.submit
            ) {
                attrs {
                    ref = submitButton
                }
                + "Import"
            }
        }
    }
}

external interface ImportFormProps: RProps {
    var applicationContext: ApplicationContext
    var token: TokenResponse
    var onImportSuccess: (import:ImportDetailResponse) -> Unit
}

external interface ImportFormState: RState {
    var imports: List<ImportResponse>
    var message: Message?
    var careflexId: String
    var apiToken: String
    var file: File?
    var isFormValidated: Boolean
}

fun RBuilder.importForm(handler: ImportFormProps.() -> Unit): ReactElement =
    child(ImportForm::class) {
        this.attrs(handler)
    }
