package de.n4.careflex.diagnosis

import de.n4.careflex.common.*
import de.n4.careflex.common.component.importListComponent
import de.n4.careflex.common.component.messageComponent
import de.n4.careflex.common.component.spinnerComponent
import de.n4.careflex.import.ImportResponse
import de.n4.careflex.infrastructure.ApplicationContext
import kotlinx.coroutines.launch
import react.*
import react.dom.*

class DiagnosisComponent(props: DiagnosisComponentProps) : RComponent<DiagnosisComponentProps, DiagnosisComponentState>(props) {

    override fun DiagnosisComponentState.init(props: DiagnosisComponentProps) {
        val importApi = props.applicationContext.importApi
        val mainScope = props.applicationContext.mainScope

        importResponse = null

        mainScope.launch {
            importApi.getImportListFor(21).also { response ->
                setState {
                    importResponse = response
                }
            }
        }
    }
    override fun RBuilder.render() {
        div(classes = "container-md") {
            h1 {
                +"Imports of last 21 days"
            }
            state.importResponse?.also { response ->
                when(response) {
                    is ApiSuccess -> importListComponent {
                        imports = response.value.content.toList()
                        onImportClick = props.onImportSelect
                    }
                    is ApiError -> messageComponent {
                        message = Message(MessageLevel.ERROR, response.error.toString())
                    }
                }
            }?: div { spinnerComponent() }
       }
    }
}

external interface DiagnosisComponentProps: RProps {
    var applicationContext: ApplicationContext
    var onImportSelect: (ImportResponse) -> Unit
}

external interface DiagnosisComponentState: RState {
    var importResponse: ApiResponse<SummaryResponse<ImportResponse>>?
}

fun RBuilder.diagnosisComponent(handler: DiagnosisComponentProps.() ->  Unit): ReactElement =
    child(DiagnosisComponent::class) {
        attrs(handler)
    }