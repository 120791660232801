package de.n4.careflex.infrastructure

import de.n4.careflex.incident.IncidentApi
import de.n4.careflex.import.ImportApi
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.MainScope

data class ApplicationContext(
    val authService: AuthService,
    val incidentApi: IncidentApi,
    val importApi: ImportApi,
    val mainScope: CoroutineScope = MainScope()
)