package de.n4.careflex.incident

import de.n4.careflex.common.ApiResponse
import de.n4.careflex.common.SummaryResponse
import de.n4.careflex.infrastructure.AuthService

class IncidentApi(
    val authService: AuthService
) {

    suspend fun getIncidentList(importId: String, limit: Int, page: Int = 0): ApiResponse<SummaryResponse<IncidentSummary>> =
        authService.authenticatedGet("rest/incident?importId=$importId&limit=$limit&page=$page")
}

external interface IncidentSummary {
    val id: String
    val timestamp: String
    val employeeId: String?
    val externalId: String?
    val type: String
    val createdAt: String
    val validFrom: String?
    val invalid: Boolean
    val errorMessage: String?
    val import: ImportStatusSummary
    val export: ExportStatusSummary?
}

external interface ImportStatusSummary {
    val id: String
    val timestamp: String
    val status: String
    val message: String?
}

external interface ExportStatusSummary {
    val id: String
    val timestamp: String
    val status: String
    val message: String?
}