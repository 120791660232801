import de.n4.careflex.CareflexApp
import de.n4.careflex.incident.IncidentApi
import de.n4.careflex.import.ImportApi
import de.n4.careflex.infrastructure.ApplicationContext
import de.n4.careflex.infrastructure.AuthService
import react.dom.*
import kotlinx.browser.document
import kotlinx.browser.window

fun main() {
    // wire application context together
    val authService = AuthService(window = window)
    val applicationContext = ApplicationContext(
        authService = authService,
        incidentApi = IncidentApi(authService = authService),
        importApi = ImportApi(authService = authService)
    )

    // render application
    render(document.getElementById("root")) {
        child(CareflexApp::class) {
            attrs.applicationContext = applicationContext
        }
    }
}