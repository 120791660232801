package de.n4.careflex.bootstrap

import kotlinx.html.ButtonType
import kotlinx.html.role
import react.*
import react.dom.button
import react.dom.div
import react.dom.span

external interface AlertProps: RProps {
    var color:Color
    var closable: Boolean
}
val alert = functionalComponent<AlertProps> { props ->
    div(classes = "alert alert-${props.color.identifier}") {
        attrs {
            role = "alert"
        }
        if (props.closable) {
            button(type = ButtonType.button,classes = "close") {
                attrs["data-dismiss"] = "alert"
                attrs["aria-label"] = "Close"
                span {
                    attrs["aria-hidden"] = "true"
                    + "&times;"
                }
            }
        }
        props.children()
    }
}
fun RBuilder.alert(color: Color, closable: Boolean = false, handler: RBuilder.() -> Unit): ReactElement =
    child(alert) {
        attrs {
            this.color = color
            this.closable = closable
        }
        handler(this)
    }

