package de.n4.careflex.common

/**
 * Simple data class to hold the message text and level.
 */
data class Message(
    val messageLevel: MessageLevel,
    val message: String
)

enum class MessageLevel{
    INFO,WARNING,ERROR
}