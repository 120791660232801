package de.n4.careflex.infrastructure

import de.n4.careflex.common.*
import de.n4.careflex.login.exceptions.AuthenticationFailedException
import de.n4.careflex.login.exceptions.NotAuthenticatedException
import kotlinx.coroutines.await
import org.w3c.dom.Window
import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.dom.url.URL
import org.w3c.dom.url.URLSearchParams
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import kotlin.js.Date

/**
 * Authentication service.
 *
 * It provides methods to acquire and clear authentication and methods to perform authenticated
 * API calls.
 *
 * It also dispatches following events:
 * [ON_LOGIN_EVENT] when the authentication was performed and was successful
 * [AUTH_EXPIRED_EVENT] when the authentication has expired ar was cleared
 *
 * @param window browser window used to perform API calls and store authentication
 */
class AuthService(
    val window: Window
): EventTargetImpl() {
    companion object {
        const val TOKEN_KEY = "de.n4.careflex.token"
        const val ON_LOGIN_EVENT = "onLogin"
        const val AUTH_EXPIRED_EVENT = "authExpired"
    }

    private var authExpiredTimeout: Int? = null

    private val apiUrl = window.get("__env").apiUrl as String

    /**
     * Create resource URL
     * Temporarily disabled - we return just the resource absolute path
     * @param resource relative path of the resource
     */
    private fun getResourceUrl(resource: String) = "$apiUrl$resource"

    /**
     * Authenticate.
     *
     * @param username username of the service user
     * @param password password of the service user
     */
    suspend fun authenticate(username: String, password: String): TokenResponse {
        val response = window.fetch(getResourceUrl("oauth/token"), RequestInit(
            method = "POST",
            body = URLSearchParams().apply {
                append("grant_type", "password")
                append("username", username)
                append("password", password)
            }
        )).await()
        if (response.status.toInt() != 200) {
            response.text().await().also { message ->
                throw AuthenticationFailedException(message)
            }
        }

        val token = response.json().await() as TokenResponse

        // save into session storage
        val json = JSON.stringify(token);
        console.info("Storing token: $json")
        window.localStorage.setItem(TOKEN_KEY, json)

        // reset timer that dispatches event when authentication expires
        authExpiredTimeout?.also { window.clearTimeout(it) }
        authExpiredTimeout = window.setTimeout({
            window.localStorage.removeItem(TOKEN_KEY)
            dispatchEvent(Event(AUTH_EXPIRED_EVENT))
        }, token.expires_in * 1000)

        // dispatch event
        println(ON_LOGIN_EVENT)
        dispatchEvent(Event(ON_LOGIN_EVENT))

        return token
    }

    /**
     * Get authentication token or null if not authenticated.
     */
    fun getAuthenticatedToken(): TokenResponse? =
        window.localStorage.getItem(TOKEN_KEY)?.let {
            JSON.parse<TokenResponse>(it)
        }?.takeIf {
            // ignore expired tokens
            val now = Date.now()
            (it.created_at + it.expires_in).toDouble() * 1000 > now
        }

    /**
     * Clear authentication token.
     */
    fun clearAuthentication() {
        window.localStorage.removeItem(TOKEN_KEY)
        dispatchEvent(Event(AUTH_EXPIRED_EVENT))
    }

    /**
     * Authenticated token.
     * @throws NotAuthenticatedException if there is no authentication
     */
    val authenticatedToken: TokenResponse
        get() = getAuthenticatedToken()?: throw NotAuthenticatedException("The user is not authenticated.")

    suspend fun <T> authenticatedGet(resource: String): ApiResponse<T> =
        authenticatedFetch("GET", getResourceUrl(resource))

    suspend fun <T> authenticatedPost(resource: String, body: Any?): ApiResponse<T> =
        authenticatedFetch("POST", getResourceUrl(resource), body)

    suspend fun <T> authenticatedPut(resource: String, body: Any?): ApiResponse<T> =
        authenticatedFetch("PUT", getResourceUrl(resource), body)

    suspend fun <T> authenticatedDelete(resource: String): ApiResponse<T> =
        authenticatedFetch("DELETE", getResourceUrl(resource))

    suspend fun <T> authenticatedFetch(method: String, resource: String, body: Any? = null): ApiResponse<T> =
        authenticatedToken.let { token ->
            window.fetch(resource, RequestInit(
                method = method,
                headers = Headers().apply {
                    append("Authorization", "bearer ${token.access_token}")
                }
            ).also { request ->
                body?.also {
                    request.body = it
                }
            })
        }.await().let { response ->
            if (response.status.toInt() == 401) {
                clearAuthentication()
                throw NotAuthenticatedException("The authentication is expired.")
            } else {
                response.json().await().let {
                    if (response.status.toInt() >= 400) {
                        ApiError(it as ApiErrorResponse)
                    } else {
                        ApiSuccess(it as T)
                    }
                }
            }
        }
}

external interface TokenResponse {
    val access_token: String
    val token_type: String
    val expires_in: Int
    val created_at: Int
}
