package de.n4.careflex.common.component

import kotlinx.html.role
import react.*
import react.dom.div
import react.dom.span

val spinner = functionalComponent<RProps> {
    div(classes = "spinner-border text-primary") {
        attrs {
            role = "status"
        }
        span(classes = "sr-only") {
            +"Loading..."
        }
    }
}
fun RBuilder.spinnerComponent(): ReactElement = child(spinner)