package de.n4.careflex

import de.n4.careflex.diagnosis.diagnosisComponent
import de.n4.careflex.import.ImportResponse
import de.n4.careflex.import.importComponent
import de.n4.careflex.import.importDetailComponent
import de.n4.careflex.infrastructure.ApplicationContext
import de.n4.careflex.infrastructure.AuthService
import de.n4.careflex.infrastructure.TokenResponse
import de.n4.careflex.login.loginForm
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import org.w3c.dom.events.Event
import react.*
import react.dom.*

class CareflexApp(props: CareflexAppProps) : RComponent<CareflexAppProps, CareflexAppState>(props) {

    override fun CareflexAppState.init(props: CareflexAppProps) {
        val authService = props.applicationContext.authService

        selectedNav = ImportAppNav

        // initialize state
        authenticationToken = authService.getAuthenticatedToken()
        authService.addEventListener(AuthService.ON_LOGIN_EVENT) {
            setState {
                authenticationToken = authService.getAuthenticatedToken()
                println("Token: $authenticationToken")
            }
        }
        authService.addEventListener(AuthService.AUTH_EXPIRED_EVENT) {
            setState {
                authenticationToken = null
            }
        }
    }

    private val authService = props.applicationContext.authService


    private val onLogoutClick: (Event) -> Unit = {
        authService.clearAuthentication()
    }

    private val showImportDetail: (ImportResponse) -> Unit = { import ->
        setState {
            selectedNav = ImportDetailAppNav(import.id)
        }
    }

    override fun RBuilder.render() {
        nav(classes = "navbar navbar-expand-sm navbar-dark bg-dark") {
            a(classes = "navbar-brand") {
                attrs { href = "#" }
                +"Careflex"
            }
            state.authenticationToken?.also {
                button(
                    classes = "navbar-toggler",
                    type = ButtonType.button
                ) {
                    attrs["data-toggle"] = "collapse"
                    attrs["data-target"] = "#navbarSupportedContent"
                    attrs["aria-controls"] = "navbarSupportedContent"
                    attrs["aria-expanded"] = "false"
                    attrs["aria-label"] = "Toggle navigation"
                    span(classes = "navbar-toggler-icon") { }
                }
                div(classes = "collapse navbar-collapse") {
                    attrs { id = "navbarSupportedContent" }
                    ul(classes = "navbar-nav mr-auto") {
                        li(classes = if (state.selectedNav is ImportAppNav) "nav-item active" else "nav-item") {
                            a(classes = "nav-link") {
                                +ImportAppNav.displayName
                                key = ImportAppNav.displayName
                                attrs { href = "#"
                                    onClickFunction = {
                                        setState {
                                            selectedNav = ImportAppNav
                                        }
                                    }
                                }
                                span(classes = "sr-only") { +"(current)" }
                            }
                        }
                        li(classes = if (state.selectedNav is DiagnosisAppNav) "nav-item active" else "nav-item") {
                            a(classes = "nav-link") {
                                +DiagnosisAppNav.displayName
                                key = DiagnosisAppNav.displayName
                                attrs {
                                    href = "#"
                                    onClickFunction = {
                                        setState {
                                            selectedNav = DiagnosisAppNav
                                        }
                                    }
                                }
                                span(classes = "sr-only") { +"(current)" }
                            }
                        }
                    }
                    form(classes = "form-inline my-2 my-lg-0") {
                        button(classes = "btn btn-outline-success my-2 my-sm-0") {
                            attrs {
                                onClickFunction = onLogoutClick
                            }
                            +"Logout"
                        }
                    }
                }
            }
        }
        state.authenticationToken?.also {
            when (state.selectedNav) {
                is ImportAppNav -> importComponent {
                    applicationContext = props.applicationContext
                    onImportSelect = showImportDetail
                }
                is DiagnosisAppNav -> diagnosisComponent {
                    applicationContext = props.applicationContext
                    onImportSelect = showImportDetail
                }
                is ImportDetailAppNav -> importDetailComponent {
                    applicationContext = props.applicationContext
                    importId = (state.selectedNav as ImportDetailAppNav).importId
                }
            }
        } ?:
        // if not authenticated
        loginForm {
            applicationContext = props.applicationContext
        }
    }
}

external interface CareflexAppProps : RProps {
    var applicationContext: ApplicationContext
}

external interface CareflexAppState : RState {
    var authenticationToken: TokenResponse?
    var selectedNav: CareflexAppNav
}

/**
 * Main-App navigation states.
 */
sealed class CareflexAppNav(val displayName: String)
object ImportAppNav: CareflexAppNav("Import")
object DiagnosisAppNav: CareflexAppNav("Diagnose")
class ImportDetailAppNav(val importId: String): CareflexAppNav("Import Detail")