package de.n4.careflex.common

/**
 * Generalised api response.
 */
sealed interface ApiResponse<T> {
    val isError: Boolean
}

/**
 * Api success response of given type.
 */
data class ApiSuccess<T>(val value: T): ApiResponse<T> {
    override val isError: Boolean = false
}

/**
 * Api error response.
 */
data class ApiError<T>(val error: ApiErrorResponse): ApiResponse<T> {
    override val isError: Boolean = true
}

/**
 * Api error data.
 */
external interface ApiErrorResponse {
    val timestamp: String
    val status: Int
    val error: String
    val message: String?
}

/**
 * Api Summary response.
 */
external interface SummaryResponse<T>{
    val totalCount: Number
    val contentCount: Number
    val content: Array<T>
}