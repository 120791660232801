package de.n4.careflex.common.component

import de.n4.careflex.bootstrap.Color
import de.n4.careflex.bootstrap.alert
import de.n4.careflex.common.Message
import de.n4.careflex.common.MessageLevel
import react.*

external interface MessageProps: RProps {
    var message: Message
}

val message = functionalComponent<MessageProps> { props ->
    when(props.message.messageLevel) {
        MessageLevel.ERROR -> Color.DANGER
        MessageLevel.INFO -> Color.SUCCESS
        MessageLevel.WARNING -> Color.WARNING
    }.let { color ->
        alert(color) {
            + props.message.message
        }
    }
}

fun RBuilder.messageComponent(handler: MessageProps.() -> Unit): ReactElement =
    child(message) {
        attrs { handler() }
    }