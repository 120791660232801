package de.n4.careflex.import

import de.n4.careflex.common.*
import de.n4.careflex.common.component.messageComponent
import de.n4.careflex.common.component.spinnerComponent
import de.n4.careflex.incident.IncidentSummary
import de.n4.careflex.infrastructure.ApplicationContext
import kotlinx.browser.document
import kotlinx.coroutines.launch
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import org.w3c.dom.css.StyleSheet
import react.*
import react.dom.*
import kotlin.js.Date

external interface ImportDetailProps : RProps {
    var applicationContext: ApplicationContext
    var importId: String
}

external interface ImportDetailState : RState {
    var pageIndex: Int
    var import: ApiResponse<ImportDetailResponse>?
    var incidents: ApiResponse<SummaryResponse<IncidentSummary>>?
    var reloadingList: Boolean
}

class ImportDetailComponent(props: ImportDetailProps) : RComponent<ImportDetailProps, ImportDetailState>(props) {

    private val defaultPageSize = 100
    private val showMaxPages = 5

    override fun ImportDetailState.init(props: ImportDetailProps) {
        val importApi = props.applicationContext.importApi
        val incidentApi = props.applicationContext.incidentApi
        val mainScope = props.applicationContext.mainScope

        pageIndex = 0
        import = null
        incidents = null
        reloadingList = false

        mainScope.launch {
            importApi.getImport(props.importId).also { response ->
                setState {
                    import = response
                }
            }
            incidentApi.getIncidentList(props.importId, defaultPageSize, pageIndex).also { response ->
                setState {
                    incidents = response
                }
            }
        }
    }

    val loadPage: (Int) -> Unit = { index ->
        setState {
            reloadingList = true
        }
        props.applicationContext.mainScope.launch {
            props.applicationContext.incidentApi.getIncidentList(props.importId, defaultPageSize, index)
                .also { response ->
                    setState {
                        reloadingList = false
                        incidents = response
                        pageIndex = index
                    }
                }
        }
    }

    override fun RBuilder.render() {
        div(classes = "container-md") {
            h1 {
                +"Import details"
            }
            // import detail
            state.import?.also { response ->
                when (response) {
                    is ApiSuccess ->
                        dl(classes = "row") {
                            dt(classes = "col-sm-3") { +"Import id" }
                            dl(classes = "col-sm-9") { +response.value.id }
                            dt(classes = "col-sm-3") { +"Channel" }
                            dl(classes = "col-sm-9") { +response.value.channel }
                            dt(classes = "col-sm-3") { +"Careflex id" }
                            dl(classes = "col-sm-9") { +response.value.careflexId }
                            dt(classes = "col-sm-3") { +"API Token" }
                            dl(classes = "col-sm-9") { +response.value.apiToken }
                            dt(classes = "col-sm-3") { +"Start" }
                            dl(classes = "col-sm-9") { +Date(response.value.start).toLocaleString() }
                            dt(classes = "col-sm-3") { +"End" }
                            dl(classes = "col-sm-9") { +(response.value.end?.let { Date(it).toLocaleString() } ?: "") }
                            dt(classes = "col-sm-3") { +"Status" }
                            dl(classes = "col-sm-9") {
                                when (response.value.status) {
                                    "SUCCESS" -> span(classes = "badge badge-success") { +response.value.status }
                                    "PARTIAL_SUCCESS" -> span(classes = "badge badge-warning") { +response.value.status }
                                    "FATAL_ERROR" -> span(classes = "badge badge-danger") { +response.value.status }
                                    "TEMP_ERROR" -> span(classes = "badge badge-danger") { +response.value.status }
                                    else -> span(classes = "badge badge-secondary") { +response.value.status }
                                }
                            }
                            response.value.message?.also { msg ->
                                dt(classes = "col-sm-3") { +"Error" }
                                dl(classes = "col-sm-9") { +msg }
                            }
                        }
                    is ApiError ->
                        messageComponent {
                            message = Message(MessageLevel.ERROR, response.error.toString())
                        }
                }
            } ?: div { spinnerComponent() }
            // incident list
            state.incidents?.also { response ->
                when (response) {
                    is ApiSuccess -> {

                        // Pagination
                        if (response.value.totalCount.toInt() > defaultPageSize) {
                            val lastPageIndex = response.value.totalCount.toInt() / defaultPageSize
                            val firstPage = (state.pageIndex - showMaxPages / 2)
                                .coerceAtMost(lastPageIndex - showMaxPages).coerceAtLeast(0)
                            val lastPage = (firstPage + showMaxPages - 1).coerceAtMost(lastPageIndex)
                            nav {
                                attrs["aria-label"] = "Page navigation"
                                ul(classes = "pagination") {
                                    li(classes = "page-item") {
                                        if (state.pageIndex <= 0) {
                                            attrs.classes += "disabled"
                                            attrs.tabIndex = "-1"
                                            attrs["aria-disabled"] = "true"
                                        }
                                        a(classes = "page-link") {
                                            attrs.onClickFunction = {
                                                loadPage(state.pageIndex - 1)
                                            }
                                            i(classes = "bi bi-caret-left-fill") {}
                                        }
                                    }
                                    for (i in firstPage..lastPage) {
                                        li(classes = "page-item") {
                                            if (state.pageIndex == i) {
                                                attrs.classes += "active"
                                                attrs["aria-current"] = "page"
                                            }
                                            a(classes = "page-link") {
                                                +"${i + 1}"
                                                attrs.onClickFunction = {
                                                    loadPage(i)
                                                }
                                            }
                                        }
                                    }
                                    li(classes = "page-item") {
                                        if (state.pageIndex >= lastPageIndex) {
                                            attrs.classes += "disabled"
                                            attrs.tabIndex = "-1"
                                            attrs["aria-disabled"] = "true"
                                        }
                                        a(classes = "page-link") {
                                            attrs.onClickFunction = {
                                                loadPage(state.pageIndex + 1)
                                            }
                                            i(classes = "bi bi-caret-right-fill") {}
                                        }
                                    }
                                }
                            }
                        }

                        // incident list
                        if (state.reloadingList)
                            div { spinnerComponent() }
                        else {
                            val firstItemIndex = state.pageIndex * defaultPageSize
                            p { +"Showing ${firstItemIndex + 1}-${firstItemIndex + response.value.contentCount.toInt()} of ${response.value.totalCount.toInt()} incidents." }
                            table(classes = "table table-sm") {
                                thead {
                                    tr {
                                        th { }
                                        th { +"Type" }
                                        th { +"Employee id" }
                                        th { +"Created at" }
                                        th { +"Valid from" }
                                        th { +"Import status" }
                                        th { +"Export status" }
                                    }
                                }
                                tbody {
                                    response.value.content.forEachIndexed { index, incident ->
                                        val invalidOrFailed = (incident.invalid || incident.export?.status?.takeIf {
                                            it in arrayOf(
                                                "FATAL_ERROR",
                                                "TEMP_ERROR"
                                            )
                                        } != null)
                                        tr {
                                            if (invalidOrFailed) attrs.classes = setOf("table-danger")
                                            key = index.toString()
                                            td {
                                                button(classes = "btn btn-link btn-sm", type = ButtonType.button) {
                                                    attrs["data-toggle"] = "collapse"
                                                    attrs["data-target"] = "#collapse_${index}"
                                                    attrs["aria-expanded"] = "false"
                                                    attrs["aria-controls"] = "collapseExample"
                                                    attrs.disabled = !invalidOrFailed
                                                    i(classes = "bi bi-caret-down-fill") {}
                                                }
                                            }
                                            td { +incident.type }
                                            td { +(incident.employeeId ?: "") }
                                            td { +Date(incident.createdAt).toLocaleDateString() }
                                            td { +(incident.validFrom?.let { Date(it).toLocaleDateString() } ?: "") }
                                            td { +(if (incident.invalid) "Incident is invalid" else "valid") }
                                            td(classes = "border-left-0") {
                                                incident.export?.let { exportStatus ->
                                                    when (exportStatus.status) {
                                                        "SUCCESS" -> span(classes = "badge badge-success") { +exportStatus.status }
                                                        "FATAL_ERROR" -> span(classes = "badge badge-danger") { +exportStatus.status }
                                                        "TEMP_ERROR" -> span(classes = "badge badge-warning") { +exportStatus.status }
                                                        else -> span(classes = "badge badge-secondary") { +exportStatus.status }
                                                    }
                                                    +" "
                                                    +Date(exportStatus.timestamp).toLocaleString()
                                                } ?: +"not yet exported"
                                            }
                                        }
                                        tr(classes = "collapse") {
                                            attrs.id = "collapse_${index}"
                                            td { }
                                            td(classes = "border-top-0") {
                                                attrs.colSpan = "6"
                                                +(incident.export?.message ?: incident.errorMessage ?: "")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    is ApiError ->
                        messageComponent {
                            message = Message(MessageLevel.ERROR, response.error.toString())
                        }
                }
            } ?: div { spinnerComponent() }
        }
    }
}

fun RBuilder.importDetailComponent(handler: ImportDetailProps.() -> Unit): ReactElement =
    child(ImportDetailComponent::class) {
        this.attrs(handler)
    }