package de.n4.careflex.common.component

import de.n4.careflex.import.ImportResponse
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import kotlin.js.Date

external interface ImportListProps: RProps {
    var imports: List<ImportResponse>
    var onImportClick: (ImportResponse) -> Unit
}

val importList = functionalComponent<ImportListProps> { props ->
    table(classes = "table table-striped table-sm") {
        thead {
            tr {
                th { attrs.colSpan = "3"}
                th(classes = "bg-primary text-white text-center") {
                attrs.colSpan = "3"
                    + "Import incident to interface"}
                th(classes = "bg-secondary text-white text-center") {
                    attrs.colSpan = "2"
                    + "Export incident to portal"}
            }
            tr {
                th { }
                th { + "Channel"}
                th { + "Start"}
                th { + "Status"}
                th(classes = "text-right") { + "Total" }
                th(classes = "text-right") { + "Valid"}
                th(classes = "text-right") { + "Exported"}
                th(classes = "text-right") { + "Failed"}
            }
        }
        tbody {
            for (import in props.imports) {
                tr {
                    if (import.failedExportCount == import.validIncidentCount || import.status in setOf("FATAL_ERROR", "TEMP_ERROR"))
                        attrs.classes = setOf("table-danger")
                    else if (import.failedExportCount.toInt() > 0 || import.status == "PARTIAL_SUCCESS")
                        attrs.classes = setOf("table-warning")
                    key = import.id
                    td {
                        button(classes = "btn btn-link btn-sm", type = ButtonType.button) {
                            attrs.onClickFunction = {
                                props.onImportClick(import)
                            }
                            i(classes = "bi bi-search") {}
                        }
                    }
                    td {
                        + import.channel
                    }
                    td {
                        + Date(import.start).toLocaleString()
                    }
                    td {
                        when(import.status) {
                            "SUCCESS" -> span(classes = "badge badge-success") { + import.status}
                            "PARTIAL_SUCCESS" -> span(classes = "badge badge-warning") { + import.status}
                            "FATAL_ERROR" -> span(classes = "badge badge-danger") { + import.status}
                            "TEMP_ERROR" -> span(classes = "badge badge-danger") { + import.status}
                            else -> span(classes = "badge badge-secondary") { + import.status}
                        }
                    }
                    td(classes = "text-right") {
                        + import.incidentCount.toString()
                    }
                    td(classes = "text-right") {
                        + import.validIncidentCount.toString()
                    }
                    td(classes = "text-right") {
                        + import.exportedIncidentCount.toString()
                    }
                    td(classes = "text-right") {
                        if (import.failedExportCount == import.validIncidentCount)
                            span(classes = "badge badge-danger") { + import.failedExportCount.toString() }
                        else if (import.failedExportCount.toInt() > 0)
                            span(classes = "badge badge-warning") { + import.failedExportCount.toString() }
                        else
                            + import.failedExportCount.toString()
                    }
                }
            }
        }
    }
}

fun RBuilder.importListComponent(handler: ImportListProps.() -> Unit): ReactElement =
    child(importList) {
        attrs { handler() }
    }