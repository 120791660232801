package de.n4.careflex.import

import de.n4.careflex.common.ApiResponse
import de.n4.careflex.common.SummaryResponse
import de.n4.careflex.common.setDate
import de.n4.careflex.infrastructure.AuthService
import org.w3c.files.File
import org.w3c.xhr.FormData
import kotlin.js.Date

class ImportApi(
    val authService: AuthService
) {
    suspend fun postImport(careflexId: String, apiToken: String, file: File): ApiResponse<ImportDetailResponse> =
        authService.authenticatedPost("rest/import", FormData().apply {
            append("careflexId", careflexId)
            append("apiToken", apiToken)
            append("file", file)
        })

    suspend fun getImport(importId: String): ApiResponse<ImportDetailResponse> =
        authService.authenticatedGet("rest/import/$importId")

    suspend fun getCsvImportList(limit: Int): ApiResponse<SummaryResponse<ImportResponse>> =
        authService.authenticatedGet("rest/import?channel=CAREFLEX_CSV&limit=$limit")

    suspend fun getImportListFor(days: Int): ApiResponse<SummaryResponse<ImportResponse>> =
        Date().also { date ->
            date.setDate(date.getDate() - days)
        }.let {
            authService.authenticatedGet("rest/import?from=${it.toISOString()}")
        }
}

external interface ImportResponse {
    val id: String
    val timestamp: String
    val status: String
    val start: String
    val end: String?
    val message: String?
    val channel: String
    val incidentCount: Number
    val validIncidentCount: Number
    val exportedIncidentCount: Number
    val failedExportCount: Number
}

external interface ImportDetailResponse {
    val id: String
    val timestamp: String
    val status: String
    val careflexId: String
    val apiToken:String
    val start: String
    val end: String?
    val message: String?
    val channel: String
}
