/*
 * Copyright 2010-2021 JetBrains s.r.o. and Kotlin Programming Language contributors.
 * Use of this source code is governed by the Apache 2.0 license that can be found in the license/LICENSE.txt file.
 */

package kotlin.text

//
// NOTE: THIS FILE IS AUTO-GENERATED by the GenerateUnicodeData.kt
// See: https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib
//

// 37 ranges totally
private object Digit {
    internal val rangeStart = intArrayOf(
        0x0030, 0x0660, 0x06f0, 0x07c0, 0x0966, 0x09e6, 0x0a66, 0x0ae6, 0x0b66, 0x0be6, 0x0c66, 0x0ce6, 0x0d66, 0x0de6, 0x0e50, 0x0ed0, 0x0f20, 0x1040, 0x1090, 0x17e0, 
        0x1810, 0x1946, 0x19d0, 0x1a80, 0x1a90, 0x1b50, 0x1bb0, 0x1c40, 0x1c50, 0xa620, 0xa8d0, 0xa900, 0xa9d0, 0xa9f0, 0xaa50, 0xabf0, 0xff10, 
    )
}

/**
 * Returns the index of the largest element in [array] smaller or equal to the specified [needle],
 * or -1 if [needle] is smaller than the smallest element in [array].
 */
internal fun binarySearchRange(array: IntArray, needle: Int): Int {
    var bottom = 0
    var top = array.size - 1
    var middle = -1
    var value = 0
    while (bottom <= top) {
        middle = (bottom + top) / 2
        value = array[middle]
        if (needle > value)
            bottom = middle + 1
        else if (needle == value)
            return middle
        else
            top = middle - 1
    }
    return middle - (if (needle < value) 1 else 0)
}

/**
 * Returns an integer from 0..9 indicating the digit this character represents,
 * or -1 if this character is not a digit.
 */
internal fun Char.digitToIntImpl(): Int {
    val ch = this.code
    val index = binarySearchRange(Digit.rangeStart, ch)
    val diff = ch - Digit.rangeStart[index]
    return if (diff < 10) diff else -1
}

/**
 * Returns `true` if this character is a digit.
 */
internal fun Char.isDigitImpl(): Boolean {
    return digitToIntImpl() >= 0
}
